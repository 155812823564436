import * as React from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import '../../styles/management.scss';

const MatthewKuzma = () => {
  return (
    <Layout>
      <SEO
        title="MATTHEW KUZMA, BAS - Management | Ostara"
        description="NUTRIENT MANAGEMENT SOLUTIONS"
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
      />
      <main className="management">
        <Container
          className="management-details-container"
          w="90%"
          paddingBottom={30}
          maxW={1694}
        >
          <Box className="manager-details">
            <div className="manager-details__text">
              <Link className="manager-details__back" to="/management">
                <ArrowBackIcon color="#044606" />
                BACK
              </Link>
              <StaticImage
                className="manager-details__img mobile"
                placeholder="transparent"
                src="../../images/matt-kuzma.jpg"
                alt=""
              />
              <Heading
                as="h2"
                color="#044606"
                className="manager-details__name"
              >
                Matthew Kuzma,
                <br />
                BAS
              </Heading>
              <Text color="#044606" className="manager-details__position">
                Vice President, Nutrient Recovery Solutions
              </Text>
              <Text color="#044606" className="manager-details__bio">
                Mr. Kuzma has more than 25 years of water and wastewater
                management experience ranging from large multinational
                corporations to venture capital funded technology start-ups.
                Prior to re-joining Ostara as Vice President, Nutrient Recovery
                Solutions, Matt held management positions with Anaergia,
                Organica Water, and US Filter/Siemens Water Technologies (now
                Evoqua). With specific expertise in nutrient recovery, anaerobic
                digestion, biological treatment of industrial and municipal
                wastewater, and membrane separation processes for water and
                wastewater, Mr. Kuzma is a seasoned expert in sustainable
                resource recovery solutions. Mr. Kuzma holds a Bachelor of
                Applied Sciences in Engineering from Miami University. Mr. Kuzma
                is also an active member of several industry organizations
                including International Water Association, Water Environment
                Federation, Water Environment Research Foundation, National
                Association of Clean Water Agencies, and the European
                Sustainable Phosphorus Platform while also serving as a board
                member for the Sustainable Phosphorus Alliance.
              </Text>
            </div>
            <StaticImage
              className="manager-details__img desktop"
              placeholder="transparent"
              src="../../images/matt-kuzma.jpg"
              alt=""
            />
          </Box>
        </Container>

        <Container className="body-cta-container" maxW={1400}>
          <Box>
            <Link to="/contact">Let’s Talk About Your Nutrient Challenges</Link>
          </Box>
        </Container>
      </main>
    </Layout>
  );
};

export default MatthewKuzma;
